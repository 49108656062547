import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCvMf8Tse6vdLv2E12Y4QDwpmWt-15rpfo",
  authDomain: "react-portfolio-2587d.firebaseapp.com",
  projectId: "react-portfolio-2587d",
  storageBucket: "react-portfolio-2587d.appspot.com",
  messagingSenderId: "433216482774",
  appId: "1:433216482774:web:6830f748d4c493087d0877",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

export { db };
